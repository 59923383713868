<template>
  <div class="index-container">
    <div class="bread"> <span @click="home()">操作台 / </span><span class="gray">取消出租</span></div>
    <el-row class="table-body">
      <div class="line clearfix">
        <h5 class="fl title">待下架车辆列表</h5>
        <div class="fr search">
          <el-input size="small" type="text" placeholder="请输入车牌号" v-model="keywords">
            <template slot="prefix">
              <el-button icon="el-icon-search" type="text" disabled></el-button>
            </template>

          </el-input>
          <el-button type="primary" size="small" @click="getList">查询</el-button>
        </div>
      </div>
      <div class="list">
        <el-table
            :data="tableData"
            style="width: 100%">
          <el-table-column
              fixed
              type="index"
              label="序号"
              width="80">
          </el-table-column>
          <el-table-column
              prop="carNum"
              label="车牌号"
              width="300">
          </el-table-column>
          <el-table-column
              prop="carBrand"
              label="品牌型号"
              min-width="120">
          </el-table-column>

          <el-table-column
              fixed="right"
              label="操作"
              width="120">
            <template slot-scope="scope">
              <el-button
                  type="text"
                  size="small" @click="HandleCanCel(scope.row)">
                下架车辆
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pages">
        <el-pagination
            background
            :hide-on-single-page="showPage"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="total">
        </el-pagination>
      </div>
    </el-row>
  </div>
</template>

<script>
import {getList} from "@/utils/api/car";
import {downUp} from "@/utils/api/car";

export default {
  name: "qxzc",
  data(){
    return{
      total:0, //总条数
      pageNo:1,
      pageSize:10,
      showPage:true,
      tableData:[],
      keywords:"",//搜索关键字
      editOne:'', //编辑数据
    }
  },
  methods: {
    home(){
      this.goHome()
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getList()
    },
    getList(){
      let data={
        type :"2",
        rows:this.pageSize,
        page: this.pageNo,
      }
      if (this.keywords !== ""){
        data.carNum=this.keywords
      }
      getList(data).then(res=>{
        if(res.code === 0){
          this.tableData = res.data.list
          this.total = Number(res.data.total)
        }
      })
    },
    //车辆下架
    HandleCanCel(row) {
        this.$confirm('此操作将下架该车辆, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {
            type: "1",
            id: row.id
          }
          downUp(data).then(res => {
            if(res.code === 0){
              this.$message({
                type: 'success',
                message: '下架成功!'
              });
            }else{
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style  lang="less">
.table-body{
  background-color: #FFFFFF;
  padding:30px ;
  padding-top: 0;
  .line{
    height: 90px;
    line-height: 90px;
    .title{
      font-size: 16px;
      font-weight: 800;
      color: #333333;
    }
    .search{
      display: flex;
      align-items: center;
      align-content: center;
      justify-items: flex-end;

      .el-input{
        font-size: 12px;
        margin-right: 12px;

        .el-input__inner{
          border: none;
          background-color: #FAFAFA;
          padding-left: 50px;
        }
        .el-input__prefix{
          width: 40px;
          &:after{
            content: '';
            width: 1px;
            height: 15px;
            background-color: #D8D7D7;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

    }
  }
}
</style>
